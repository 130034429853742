<template>
  <div class="Appdownload">
    <div class="bigImg">
      <div class="codeBox">
        <canvas class="canvasCode" ref="qrcodeCanvas"></canvas>
        <!-- <img src="../../static/appDownload/code.png" alt=""> -->
        <p>扫码下载</p>
      </div>
    </div>
    <div class="iconBox">
      <div class="ibsBox">
        <div>
          <img src="../../static/appDownload/zx.png" alt="">
          <span>丰富的行情资讯</span>
        </div>
        <div>
          <img src="../../static/appDownload/bj.png" alt="">
          <span>精准的产品报价</span>
        </div>
        <div>
          <img src="../../static/appDownload/jl.png" alt="">
          <span>精彩的广场互动</span>
        </div>
        <div>
          <img src="../../static/appDownload/zys.png" alt="">
          <span>智能止盈止损</span>
        </div>
        <div>
          <img src="../../static/appDownload/hd.png" alt="">
          <span>丰富的新手活动</span>
        </div>
        <div>
          <img src="../../static/appDownload/zc.png" alt="">
          <span>点差低，免费注册</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { generateQRCode } from "../../utils/tools";

  export default {
    name: "Appdownload",
    metaInfo: {  
      title: '红狮金业APP,现货黄金白银投资专业软件,伦敦金伦敦银靠谱平台,黄金交易红狮启富APP',  
      meta: [  
        { name: 'keywords', content: '红狮金业,红狮启富,黄金交易平台,贵金属交易软件,贵金属投资APP,贵金属平台,现货黄金开户,现货白银,正规靠谱平台。' },  
        { name: 'description', content: '红狮金业APP是一款稳定安全的交易软件，专注于为客户提供24小时全球金融产品在线交易，包括现货黄金、现货白银、贵金属等产品的开户、投资交易服务。' }  
      ]  
    },
    created(){
      // 传参控制显示顶部和底部
      this.$emit('controlShow', false);
    },
    mounted() {
      const { origin } = window.location;
      const channel = sessionStorage.getItem('channel');
      let url = origin + '/subject/download?channel=' + channel
      const icon = require('../../static/index/hongshi.jpg')
      generateQRCode(url, this.$refs.qrcodeCanvas, icon)
    },
    methods: {

    },
  }
</script>

<style lang="less" scoped>
  .Appdownload{
    width: 100%;
    margin: 0 auto;
    text-align: center;
    .bigImg{
      width: 100%;
      height: 500px;
      background: url('../../static/appDownload/banner.png') no-repeat;
      background-size: cover;
      position: relative;
      .codeBox{
        width: 140px;
        height: auto;
        position: absolute;
        right: 450px;
        bottom: 40px;
        img{
          width: 100%;
          height: auto;
        }
        .canvasCode {
          width: 140px !important;
          height: 140px !important;
        }
        p{
          margin: 0;
          background: #e1251a;
          box-sizing: border-box;
          padding: 10px 0;
          color: #fff;
          margin-top: -4px;
        }
      }
    }
    .iconBox{
      width: 100%;
      height: 760px;
      box-sizing: border-box;
      padding: 80px 20px;
      background: url('../../static/appDownload/gnBanner.png') no-repeat;
      background-size: cover;
      .ibsBox{
        width: 1130px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        div{
          width: 31.5%;
          height: 270px;
          background-color: #fff;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: center;
          margin-bottom: 20px;
          img{
            width: 80px;
            height: 80px;
            margin-bottom: 40px;
          }
          span{
            font-size: 18px;
            font-weight: 600;
            line-height: 40px;
            margin-bottom: 40px;
          }
        }
        div:nth-child(1) img{
          width: 70px;
          height: auto;
        }
        div:nth-child(6) img{
          width: 60px;
        }
        div:hover {
          transition: transform 0.35s ease;
          background-color: #e1251a;
        }
        div:hover span{
          color: #fff;
        }
      }
    }
  }
</style>